import classNames from 'classnames';
import React from 'react';

import { ChatButtonIconProps } from './chat-button-icon.props';

const ChatButtonIcon = ({ className, style }: ChatButtonIconProps) => {
    const classes = classNames('chat-button-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3040_8766)">
                    <path
                        d="M12.4971 2C10.6887 2.00057 8.91441 2.49175 7.36309 3.42125C5.81178 4.35075 4.54155 5.68374 3.68771 7.27826C2.83386 8.87277 2.42838 10.6691 2.51444 12.4759C2.6005 14.2827 3.17489 16.0323 4.17643 17.5384L2.5 22L8.11373 20.9846C9.4655 21.6453 10.9489 21.9924 12.4534 21.9998C13.958 22.0073 15.4448 21.675 16.803 21.0276C18.1612 20.3803 19.356 19.4346 20.2979 18.2611C21.2399 17.0877 21.9051 15.7167 22.2435 14.2503C22.5821 12.7839 22.5855 11.26 22.2533 9.79218C21.9211 8.32433 21.2621 6.95042 20.3252 5.77286C19.3883 4.59528 18.1977 3.64445 16.8423 2.99119C15.4869 2.33793 14.0016 1.99911 12.4971 2Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle
                        cx="7.5"
                        cy="11.5"
                        r="0.5"
                        transform="rotate(180 7.5 11.5)"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="2"
                    />
                    <circle
                        cx="12.5"
                        cy="11.5"
                        r="0.5"
                        transform="rotate(180 12.5 11.5)"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="2"
                    />
                    <circle
                        cx="17.5"
                        cy="11.5"
                        r="0.5"
                        transform="rotate(180 17.5 11.5)"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="2"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3040_8766">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default ChatButtonIcon;
