import classNames from 'classnames';

import { DownloadButtonIconProps } from './download-button-icon.props';

const DownloadButtonIcon = ({ className, style }: DownloadButtonIconProps) => {
    const classes = classNames('chat-button-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3040_8778)">
                    <path
                        d="M8.26923 13.6923L12.5 17.9231M12.5 17.9231L16.7308 13.6923M12.5 17.9231V6.07692M12.5 23C18.5752 23 23.5 18.0752 23.5 12C23.5 5.92487 18.5752 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0752 6.42487 23 12.5 23Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3040_8778">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default DownloadButtonIcon;
